<template>
  <div id="contact-us">
    <page-content
      class="contact-page-header"
      title="Contact Us"
      subtitle="Let's discuss your project! Please provide us with a brief description with what you already have and what you are going to achieve. Our team will contact you within 1x24 business hour."
    >
      <div class="d-flex justify-content-center align-items-center">
        <div class="d-flex align-items-center mr-2">
          <feather type="mail" class="mr-2 text-primary" />
          <span>sales@nostratech.com</span>
        </div>
        <div class="d-flex align-items-center ml-2">
          <feather type="phone-call" class="mr-2 text-primary" />
          <span>+62 21 2927 7878</span>
        </div>
      </div>
    </page-content>

    <section id="contact-form" class="pt-0">
      <div class="container">
        <b-form class="contact-us-form w-100 text-center" @submit.prevent="onSubmit">
          <b-form-group class="text-left form-mb" label="Full Name*" label-class="form-label">
            <b-form-input v-model="contactUs.customerName" name="fullName" required></b-form-input>
          </b-form-group>

          <b-row class="form-mb email-phone-form">
            <b-col cols="12" md="6">
              <b-form-group class="text-left" label="Email address*" label-class="form-label">
                <b-form-input v-model="contactUs.email" type="email" name="email" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group class="text-left" label="Phone Number*" label-class="form-label">
                <b-form-input type="tel" v-model="contactUs.mobileNumber" name="phoneNumber" required v-number-only></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group class="text-left form-mb" label="Company name*" label-class="form-label">
            <b-form-input v-model="contactUs.companyName" name="companyName" required></b-form-input>
          </b-form-group>

          <b-form-group class="text-left form-mb" label="How did you hear about us*" label-class="form-label">
            <b-form-select v-model="contactUs.reference" :options="options" required class="form-control" name="reference"></b-form-select>
          </b-form-group>

          <b-form-group class="text-left form-mb" label="Tell us about your project*" label-class="form-label">
            <b-form-textarea v-model="contactUs.projectDescription" required rows="5" name="description"></b-form-textarea>
          </b-form-group>

          <b-alert :show="alert.show" :variant="alert.type" dismissible class="text-left">{{ alert.message }}</b-alert>

          <b-button variant="primary" class="px-75px" type="submit" :disabled="isSubmitDisabled">
            Send Message
          </b-button>
        </b-form>
      </div>
    </section>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BAlert, BButton, BRow, BCol } from "bootstrap-vue";

import { MAIL_SUBJECT_ID, MAIL_BODY_ID, MAIL_SEND_TO } from "@/configs/EmailConfig";
import ContactService from "@/services/ContactService";

const PageContent = () => import("@/components/PageContent.vue");

export default {
  name: "ContactUs",
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BAlert,
    BButton,
    BRow,
    BCol,
    PageContent,
  },
  data: () => ({
    contactUs: {
      email: "",
      mobileNumber: "",
      companyName: "",
      customerName: "",
      projectDescription: "",
      reference: null,
    },
    options: [
      { value: null, text: "Please select an option" },
      { value: "Google Search", text: "Google Search" },
      { value: "Referral", text: "Referral" },
      { value: "Instagram", text: "Instagram" },
      { value: "LinkedIn", text: "LinkedIn" },
      { value: "In a blog or news article", text: "In a blog or news article" },
      { value: "Other", text: "Other" },
    ],
    isSubmitDisabled: false,
    alert: {
      type: "success",
      message: "",
      show: false,
    },
  }),
  methods: {
    onSubmit() {
      const keys = Object.keys(this.contactUs);
      let valid = true;
      this.alert.show = false;

      keys.forEach((item) => {
        if (!this.contactUs[item]) valid = false;
      });

      if (!valid) {
        this.alert.show = true;
        this.alert.type = "danger";
        this.alert.message = "Can't process your request because some fields are not valid!";

        return;
      }

      this.isSubmitDisabled = true;

      const payload = {
        subject: {
          template: {
            id: MAIL_SUBJECT_ID,
            data: {
              companyName: this.contactUs.companyName,
            },
          },
        },
        body: {
          template: {
            id: MAIL_BODY_ID,
            data: {
              customerName: this.contactUs.customerName,
              companyName: this.contactUs.companyName,
              email: this.contactUs.email,
              mobileNumber: this.contactUs.mobileNumber,
              projectDescription: this.contactUs.projectDescription,
              reference: this.contactUs.reference,
            },
          },
        },
        to: MAIL_SEND_TO,
      };

      ContactService.sendContact(payload)
        .then(() => {
          this.isSubmitDisabled = false;

          this.alert.show = true;
          this.alert.type = "success";
          this.alert.message = "Your request has been successful and will be processed!";
        })
        .catch(() => {
          this.isSubmitDisabled = false;
        });
    },
  },
  directives: {
    numberOnly: {
      bind(el) {
        el.addEventListener("keyup", () => {
          let regex = /^[0-9]*$/;
          if (!regex.test(el.value)) {
            el.value = el.value.slice(0, -1);
          }
        });
      },
    },
  },
};
</script>
