import axios from "axios";

import { MAIL_API_KEY, SEND_CONTACT_ENDPOINT } from "@/constant/ApiConstant";

export const sendContact = (params) =>
  new Promise((resolve, reject) => {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": MAIL_API_KEY,
    };

    axios
      .post(SEND_CONTACT_ENDPOINT, params, { headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response));
  });

export default {
  sendContact,
};
